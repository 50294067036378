import React, { Component } from 'react'
import LazyLoad from 'react-lazyload';

// Background
import Image from '../../components/Image/index'
import coverWebp from "../../Assets/img/LORD-ESPERANZA-PHOENIX-COVER.webp"
import coverPng from "../../Assets/img/LORD-ESPERANZA-PHOENIX-COVER.png"
import BackgroundWebp from "../../Assets/img/BOUTIQUE/LORD-ESPERANZA-WEBSITE-BACKGROUND-001.webp";
import BackgroundPng from "../../Assets/img/BOUTIQUE/LORD-ESPERANZA-WEBSITE-BACKGROUND-001.png";
const cover = require("../../Assets/img/LORD-ESPERANZA-PHOENIX-COVER.jp2");
const BackgroundJPF = require("../../Assets/img/BOUTIQUE/LORD-ESPERANZA-WEBSITE-BACKGROUND-001.jp2");


class Accueil extends Component {
	render() {
		return (
			<div style={{width: "100%", height: "100%", display: "flex", alignItems: "center"}}>
            {/* Container taking full width for background */}

                <Image 
                    src={BackgroundWebp} 
					fallback={BackgroundJPF}
					fallbackPng={BackgroundPng} 
                    isBackground 
                    className="w-100 h-100 position-absolute"
                    alt="Tracklist background section"
                    />
				<div style={{zIndex: 2, width: "100%", textAlign: "center"}}>
					<a target="_blank" rel="noopener noreferrer nofollow" href={process.env.REACT_APP_LNK_TO}>
						<LazyLoad>
							<Image 
								src={coverWebp} 
								fallback={cover} 
								fallbackPng={coverPng}
								alt="lord-esperanza-album-cover" 
								size={{width:"30px", heigth:"30px"}}
								style={{width: "60vmin", height: "auto"}}
								/>
						</LazyLoad>
					</a>
				</div>
            </div>
		);
	}
}

export default Accueil;