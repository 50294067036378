import React, { Component } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';

import './App.css';

import App2 from './App';
import Secret from './Secret/Accueil'



class App extends Component {
  render() {
    return (
      <div>
       <Routes> 
          <Route index path="/" element={<App2/>}/>
          <Route path="/lesombres" element={<Navigate replace to="https://lesombres.lordesperanza.com" />} />
          <Route path="/125420222" element={<Secret/>}/>
        </Routes>
      </div>
    );
  }
}

export default App;