import styled from "styled-components"
import Nav from "react-bootstrap/Nav";
import NavbarBrand from "react-bootstrap/NavbarBrand";
import { Menu, Close } from "./icons"

export const Bar = styled.div`
    display: inline-flex;
    width: 100%;
    background-color: transparent;
    position: absolute;
    z-index: 1;
`;

export const NavbarTitle = styled(NavbarBrand)`
    display: inline;
    font-size: 3rem;
`;

export const NavbarMenu = styled(Nav)`
    margin-left: auto;
    align-self: center;
`;

export const NavbarButton = styled(Nav.Link)`
    color: ${props => props.theme.colors.white} !important;
    font-family: elza-text, sans-serif;
    font-weight: 600;
    text-transform: uppercase;
    margin-right: 0.8rem;
    /*
    &.active{
        color: ${props => props.theme.colors.main} !important;
    }
    */
`;

export const NavbarToggle = styled(Menu)`
    color: ${props => props.theme.colors.white} !important;
    border: unset !important;
`;

export const NavbarToggleButton = styled.button`
    background-color: transparent;
    border: unset;
    outline: unset;
    cursor: pointer;
    color: ${props => props.theme.colors.white} !important;
`;

export const NavbarToggleClose = styled(Close)`
    color: ${props => props.theme.colors.white} !important;
    border: unset !important;
`;

