import React, { Component } from 'react'

import './Accueil.css'

import cover from '../Assets/img/secret.png'
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { ThemeProvider, createGlobalStyle, ThemeContext } from 'styled-components';
import Logo from "./logo";


const GlobalStyle = createGlobalStyle`
	body{

		font-family: elza-text,sans-serif;
	}
`;

class Accueil extends Component {
	render() {
		return (
			<div className="App2">
    
				<Navbar expand="lg">
					<Navbar.Brand href="./"> <Logo/></Navbar.Brand>
				</Navbar>

				<header className="App-header2">

					<img src={cover} className="App-logo2" alt="logo" />
				</header>
			</div >
		);
	}
}

export default Accueil;