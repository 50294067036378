import React, {useEffect} from "react";
import { Title, Link } from "./index.style";

// Background
import Image from '../../components/Image/index'
import BackgroundWebp from '../../Assets/img/BOUTIQUE/LORD-ESPERANZA-WEBSITE-BACKGROUND-001.webp';
import BackgroundPng from '../../Assets/img/BOUTIQUE/LORD-ESPERANZA-WEBSITE-BACKGROUND-001.png';
import ItemsPng from '../../Assets/img/LORD-PHOENIX-PHYSIQUE-ITEMS.png';
import ItemsWebp from '../../Assets/img/LORD-PHOENIX-PHYSIQUE-ITEMS.webp';
import { Container } from "react-bootstrap";
const ItemsJPF = require('../../Assets/img/LORD-PHOENIX-PHYSIQUE-ITEMS.jp2'); 
const BackgroundJPF = require('../../Assets/img/BOUTIQUE/LORD-ESPERANZA-WEBSITE-BACKGROUND-001.jp2'); 

export default () => {
    return(
        <div style={{width: "100%", height: "100%", display: "flex", alignItems: "center"}}>
        {/* Container taking full width for background */}

            <Image 
                src={BackgroundWebp} 
                fallback={BackgroundJPF}
                fallbackPng={BackgroundPng}
                isBackground 
                className="w-100 h-100 position-absolute"
                alt="Shop background section"
                />
            <Container>
                <Link href={process.env.REACT_APP_SHOP} target="_blank" rel="noopener noreferrer" className="d-inline-block">
                    <Image 
                        src={ItemsWebp} 
                        fallback={ItemsJPF}
                        fallbackPng={ItemsPng} 
                        className="position-relative"
                        alt="Shop link"
                        size={{width:"100%", heigth:"auto"}}
                    />
                </Link>
            </Container>
        </div>
    );
};