import React from "react";

import Container from 'react-bootstrap/Container'
import { TourSvg } from "./tour";

const Concerts = () => {
    return (
      <>
        <Container>
          <a href="https://lordesperanza.bleucitron.net" target="_blank" rel="noopener noreferrer">
            <TourSvg />
          </a>
        </Container>
      </>
    );
    
    
}

export default Concerts;