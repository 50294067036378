import { DefaultTheme } from "styled-components"

const theme: DefaultTheme = {
    colors: {
        main: "#c4533d",
        white: "#EFEFEF",
        darkGrey: "#121212",
        black: "#151515"
    }
}

export default theme