import styled from "styled-components";

export const Title = styled.h2`
    text-align: center;
`;

export const Link = styled.a`
    color: ${props => props.theme.colors.white};
    font-size: 1.2rem;
    font-weight: 700;
    background-color: ${props => props.theme.colors.main};
    border-radius: 2px;
    padding: 0 0.9rem;
    @media only screen and (max-width: 500px){
        font-size: 0.9rem;
    }
`;