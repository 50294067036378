import React, { useState } from "react";

import Navbar from "react-bootstrap/Navbar";

import { NavbarMenu, NavbarToggleButton, NavbarButton, NavbarToggle, NavbarToggleClose } from "./index.style";
import Logo from "./logo";

const NavBar = () => {
    const [opened, setOpened] = useState(false);
    return(
        <Navbar bg="transparent" expand="md" fixed="top" variant="dark">
            <Logo/>
             <Navbar.Toggle 
                    as={() => (
                        <NavbarToggleButton className="d-md-none" onClick={() => setOpened(!opened)}>
                            {!opened ? <NavbarToggle width="40" height="40"/> : <NavbarToggleClose width="40" height="40"/>}
                        </NavbarToggleButton>
                    )}
                    aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav" className={opened ? "show" : ""}>
                <NavbarMenu>
                    <NavbarButton href="#tracklist">MUSIQUE</NavbarButton>
                    <NavbarButton href="#shows">CONCERTS</NavbarButton>
                    <NavbarButton href="#video">VIDÉOS</NavbarButton>
                    <NavbarButton href={process.env.REACT_APP_SHOP} target="_blank" rel="noopener noreferrer">BOUTIQUE</NavbarButton>
                </NavbarMenu>
            </Navbar.Collapse>
        </Navbar>
    );
}

export default NavBar