import React, { useState, createRef, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Image from '../../components/Image/index';

import BackgroundWebp from '../../Assets/img/VIDEO/LORD-ESPERANZA-WEBSITE-BACKGROUND-001.webp';
import BackgroundPng from '../../Assets/img/VIDEO/LORD-ESPERANZA-WEBSITE-BACKGROUND-001.png';
const BackgroundJPF = require('../../Assets/img/VIDEO/LORD-ESPERANZA-WEBSITE-BACKGROUND-001.jp2'); 

const handleType = (ev: React.SyntheticEvent<HTMLEmbedElement, Event>) => {
    ev.currentTarget.type = "video/mp4";
}

interface Vid {
    id: string
    alt: string
}

const Video = () => {
    /* 
    - Video set-up
    */
    const videos = {
        default: { id: "RZZwsvHGKH4", alt: "Lord Esperanza - Paradis feat. Nemir" },
        gallery: [
            { id: "wWspVO8pYns", alt: "Lord Esperanza - Caméléon" },
            { id: "aW4A5AYVp6I", alt: "Lord Esperanza - Les Ombres" }
        ],
        thumbnail: "hqdefault"
    }
    const [video,setVideo] = useState(videos.default);
    const [gallery,setGallery] = useState(videos.gallery);
    const embedVideo = (newVideo: Vid) => {
        gallery.splice(gallery.indexOf(newVideo),1); // removing newVideo from gallery
        setGallery( gallery.concat([video]) ); // adding currentVideo to gallery
        setVideo(newVideo); // setting new video
    }

    return(
        <div style={{width: "100%", height: "100%", display: "flex", alignItems: "center"}}>
            {/* Container taking full width for background */}
            <style type="text/css">
                {`
                    @media (min-width: 1200px) {
                        .video-embed {
                            min-height: 70vh;
                        }
                    }
                    @media (max-width: 1200px) {
                        .video-embed {
                            min-height: 40vh;
                        }
                    }
                    @media (max-width: 400px) {
                        .video-embed {
                            padding-top: 10rem;
                        }
                    }
                `}
            </style>

            <Image 
                src={BackgroundWebp} 
                fallback={BackgroundJPF} 
                fallbackPng={BackgroundPng}
                isBackground 
                className="w-100 h-100 position-absolute"
                alt="Video background section"
                />
            <Container className="px-xl-7 my-5 video-embed" style={{zIndex: "2"}}>
                {/* Make video responsive */}
                <iframe 
                    className="px-xl-7"
                    title={video.id} width="100%" style={{minHeight: "450px"}}
                    src={`https://www.youtube.com/embed/${video.id}`} frameBorder="0" 
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
                    allowFullScreen
                    loading="lazy">
                </iframe>
                {/* Image Gallery */}
                <Row className="mx-1 mt-2">
                    {gallery.map((video: Vid)=>{
                        return(
                            <Col className="p-0">
                                <button 
                                    aria-label={video.alt}
                                    onClick={() => embedVideo(video)} // replace video
                                    style={{width: "inherit", border: "unset", background: "transparent"}} // removing default button styles
                                >
                                    <img 
                                        style={{width:"auto", height: "inherit", maxHeight: "150px" ,borderRadius: "12px"}} 
                                        src={`https://img.youtube.com/vi/${video.id}/hqdefault.jpg`}
                                        alt={video.alt}
                                    />
                                </button>
                            </Col>
                        );
                    })}
                </Row>
            </Container>
        </div>
        
    );
}

export default Video;