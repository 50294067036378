import React from "react";

type Icon = {
    width: string, 
    height: string
}

export const Menu = ({width = "40", height = "40"}: Icon) => (
    <svg viewBox="0 0 24 24" height={height} aria-hidden="true" focusable="false" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-controls="basic-navbar-nav" aria-label="Toggle navigation">
        <path d="M4 6h16v2H4zm0 5h16v2H4zm0 5h16v2H4z"></path>
    </svg>
);

export const Close = ({width = "40", height = "40"}: Icon) => (
    <svg viewBox="0 0 512 512" height={height} aria-hidden="true" focusable="false" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-controls="basic-navbar-nav" aria-label="Toggle navigation">
        <polygon points="400 145.49 366.51 112 256 222.51 145.49 112 112 145.49 222.51 256 112 366.51 145.49 400 256 289.49 366.51 400 400 366.51 289.49 256 400 145.49"></polygon>
    </svg>
);