import React, { Component, useState, useEffect, createRef } from 'react'

// Bootstrap
import Container from 'react-bootstrap/Container'

// Background
import Image from '../../components/Image/index'
import BackgroundWebp from '../../Assets/img/MUSIQUE/LORD-ESPERANZA-WEBSITE-BACKGROUND-002.webp';
import BackgroundPng from '../../Assets/img/MUSIQUE/LORD-ESPERANZA-WEBSITE-BACKGROUND-002.png';
const BackgroundJPF = require('../../Assets/img/MUSIQUE/LORD-ESPERANZA-WEBSITE-BACKGROUND-002.jp2'); 

const Tracklist = () => {
    return (
        <div style={{width: "100%", height: "100%", display: "flex", alignItems: "center"}}>

            <Image 
                src={BackgroundWebp} 
                fallback={BackgroundJPF} 
                fallbackPng={BackgroundPng}
                isBackground 
                className="w-100 h-100 position-absolute"
                alt="Tracklist background section"
                />
            <Container className="p-xl-5" style={{zIndex: "2"}}>
                <iframe 
                    style={{borderRadius: "12px"}}
                    src="https://open.spotify.com/embed/album/2JsVPPMBrRVbAKcMVhQD6i" 
                    width="100%" 
                    height="352"
                    allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" 
                    loading="lazy">
                </iframe>
            </Container>
        </div>
    );
}

export default Tracklist;
